<template>
  <div
    class="background-image position-relative d-flex align-items-start flex-column"
  >
    <div class="mb-auto page-header text-center w-100 d-flex flex-row justify-content-center align-content-center mt-3">
      <div class="w-auto d-flex flex-row gap-3">
        <img class="bmw-logo" src="../assets/images/bmw_logo.svg"  alt=""/>
        <img class="mini-logo" src="../assets/images/mini_logo.svg"  alt=""/>
      </div>
    </div>
    <div class="mb-auto page-content p-3" />
    <div class="page-footer p-3">
      <div class="d-grid gap-2 col-md-6 col-lg-4 mx-md-auto">
        <router-link
          :to="`/configuration/${$route.params.configurationGuid}/thanks`"
          class="btn btn-primary rounded-0"
          type="button"
        >
          {{ $t('continue') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount () {
    this.$store.dispatch('questions/getQuestions', this.$route.params.configurationGuid)
  }
}
</script>

<style lang="scss" scoped>
.bmw-logo {
  max-width: 5rem;
}
.mini-logo {
  max-width: 9rem;
}
.background-image {
  background: url("../assets/images/start_page.jpg") center no-repeat;
  background-size: cover;
  height: 100%;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 30vh;
    z-index: 1;
  }

  &:before {
    top: 0;
    background-image: linear-gradient(to bottom, white 40%, transparent);
  }

  &:after {
    bottom: 0;
    background-image: linear-gradient(to top, white 40%, transparent);
  }

  > div {
    z-index: 2;
    width: 100vw;
  }
}
</style>
